<template>
  <NewModelLayout>
    <template #title>
      <h1 class="text-2xl">
        Add New Product / Part
      </h1>
    </template>
    <template #form>
      <vue-form
        :form="form"
        :errors.sync="error.errors"
        ref="form"
        class="bg-gray-100 shadow rounded-sm"
      >
        <div class="bg-white px-6 py-4">
          <div class="flex flex-wrap w-row-offset-sm -mx-2">
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.name"
                :errors="error.errors.name"
                name="name"
                prop="name"
                label="Name"
                description="The name of the product."
                required
              />
            </div>
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.slug"
                :errors="error.errors.slug"
                name="slug"
                prop="slug"
                label="Slug"
                description="The slug for this product. Auto-generated when the name is filled."
                required
              />
            </div>
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.sku"
                :errors="error.errors.sku"
                name="sku"
                prop="sku"
                label="SKU"
                description="The unique identifier for this product."
                required
              />
            </div>
            <div class="w-full px-2">
              <hr class="mt-4 mb-8" />
            </div>
            <div class="flex items-center w-full px-2 mb-4">
              <vue-checkbox
                v-model="form.is_part"
                :value="form.is_part"
                :label="form.is_part ? 'This product is a part/component' : 'This is a single product'"
                :description="form.is_part ? 'Checked if this a part that can be selected as a component for builds.' : 'Unchecked if this is a product with pre-set parts (ie. a pre-built), or sold individually (ie. a monitor).'"
                name="is_part"
                prop="is_part"
              />
            </div>
            <div class="flex items-end w-full px-2 mb-4">
              <template v-if="form.is_part">
                <vue-select
                  v-model="form.extra_data.assembly_key"
                  :options="partBuildSetFields"
                  :disable-on-null="false"
                  :dropdownLabel="(option) => {
                    return `${option.name} (${option.key})`
                  }"
                  valueKey="key"
                  name="assembly_key"
                  prop="assembly_key"
                  label="Assembly Key"
                  description="The assembly key of this product, defining the type."
                  class="flex-grow"
                />
              </template>
              <template v-else>
                <vue-select
                  v-model="form.assembly_set_id"
                  :options="formattedSets"
                  :disable-on-null="false"
                  name="assembly_set_id"
                  prop="assembly_set_id"
                  labelKey="label"
                  valueKey="key"
                  label="Assembly Set"
                  description="The assembly set this product is assigned to."
                  class="flex-grow"
                />
                <vue-button
                  :icon="['fas', 'sync']"
                  text
                  sm
                  aria-label="Reload sets"
                  class="h-12 ml-2"
                  @click="getSets"
                />
              </template>
            </div>
            <div class="w-full px-4">
              <hr class="mt-4 mb-8" />
            </div>
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.price"
                name="price"
                prop="price"
                type="number"
                step="0.01"
                min="0"
                prepend="£"
                label="Price"
                description="The price of this product."
                required
              />
            </div>
            <div class="w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.discounted_price"
                name="discounted_price"
                prop="discounted_price"
                type="number"
                step="0.01"
                min="0"
                prepend="£"
                label="Discounted Price"
                description="The price this product is discounted at."
              />
            </div>
            <div class="hidden w-full lg:w-1/2 px-2 mb-4">
              <vue-input-group
                v-model="form.tax_rate"
                name="tax_rate"
                prop="tax_rate"
                type="number"
                step="0.5"
                min="0"
                max="100"
                append="%"
                label="Tax Rate"
                description="The tax rate in percent."
                required
              />
            </div>
            <div class="hidden flex items-center w-full px-2 mb-4">
              <vue-checkbox
                v-model="form.tax_included_in_price"
                :value="form.tax_included_in_price"
                name="tax_included_in_price"
                prop="tax_included_in_price"
                label="Tax included in price?"
                description="Checked if the tax was calculated into the price."
              />
            </div>
            <div class="hidden flex items-center w-full px-2 mb-4">
              <vue-checkbox
                v-model="form.display_with_tax"
                :value="form.display_with_tax"
                name="display_with_tax"
                prop="display_with_tax"
                label="Display price with tax?"
                description="Checked if the frontend should show the total price with tax."
              />
            </div>
            <div class="w-full px-2 mb-4">
              <vue-datetime
                v-model="form.live_at"
                :value="form.live_at"
                name="live_at"
                prop="live_at"
                label="Publish Date"
                description="Set when you wish this product to go live. Leave blank if you want it to remain private."
              />
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center w-full px-4 py-4">
          <vue-button
            text
            error
            @click="resetForm"
          >
            Reset
          </vue-button>
          <vue-button
            :icon="['fas', 'save']"
            type="submit"
            success
            @click="submitProduct"
          >
            Add Product
          </vue-button>
        </div>
      </vue-form>
    </template>
  </NewModelLayout>
</template>
<script>
import { map, cloneDeep } from 'lodash'
import NewModelLayout from '@/components/layouts/NewModelLayout'

export default {
  name: 'ProductsNew',

  components: {
    NewModelLayout
  },

  data () {
    return {
      loading: true,
      sets: [],
      form: {},
      defaultBuildSet: {},
      defaults: {
        name: '',
        slug: '',
        sku: '',
        extra_data: {
          assembly_key: null,
          default_game: null,
          default_resolution: null,
          default_detail: null
        },
        price: 0,
        discounted_price: 0,
        tax_rate: 20,
        tax_included_in_price: true,
        display_with_tax: false,
        is_part: false,
        assembly_set_id: null
      },
      error: {
        errors: {}
      }
    }
  },

  computed: {
    formattedSets: function () {
      return [
        ...[{ key: null, label: 'None' }],
        ...map(this.sets, (set) => { return { key: set.id, label: set.name } })
      ]
    },

    partBuildSetFields () {
      return this.defaultBuildSet.fields
    }
  },

  watch: {
    'form.name': function (val) {
      this.form.slug = val.toLowerCase().replace(/-+/g, ' ').replace(/ +/g, '-').replace(/[^\w-]+/g, '')
    },

    'form.is_part': function (newVal, oldVal) {
      if (newVal) {
        this.form.assembly_set_id = null
      }
    }
  },

  mounted () {
    this.getSets()
    this.getBuildSet()
    this.initForm()
  },

  methods: {
    initForm () {
      this.form = cloneDeep(this.defaults)
      if (this.$route.query.part) {
        this.form.is_part = true
      }
    },

    resetForm () {
      this.initForm()
    },

    getSets () {
      this.loading = true
      this.$api.get('sets')
        .then(({ data: res }) => {
          this.sets = res
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    getBuildSet () {
      this.loading = true
      this.$api.get('public/sets/default-build-set', {
        params: {
          withPivot: true,
          with: ['assemblies']
        }
      })
        .then((res) => {
          this.defaultBuildSet = res.data
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    submitProduct () {
      this.$refs.form.validate()
      this.$api.post('products', {
        ...this.form,
        ...{
          tax_rate: (this.form.tax_rate / 100)
        }
      })
        .then((res) => {
          this.$router.push({
            name: 'products.single',
            params: {
              model: res.data.id
            }
          })
        })
        .catch((err) => {
          this.error = err
        })
    }
  }
}
</script>
